<template>
  <kst-section items-mode>
    <StockSearchDefault sell-price
      @init="handleStock_Init"
      @loaded="handleStock_Loaded"
      @submit="handleStock_Submit"
    />

    <template #top-right>
      <template v-if="userData.WarehouseID !== null">
        <kst-button action-mode
          class="is-primary"
          :label="Model.SpadItem.Module.FullName"
          :moduleType="Model.SpadItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template #bottom>
      <template v-if="userItems.length > 0">
        <kst-button action-mode
          class="is-primary"
          :label="Model.SpadItem.Module.FullName"
          :moduleType="Model.SpadItem.ModuleType.New"
          @click="handleAdd_Item"
        />
      </template>
    </template>

    <template v-if="userData.WarehouseID !== null">
      <List
        class="block"
        :userData="userData"
        :userItems="userItems"
      />

      <kst-input class="is-hidden"
        field="StockMissingQty" :data="userData" :catalog="Catalog"
      />
      <kst-errors class="block" :items="invalidItems"/>

      <Scanner
        :itemList="itemList"
        :userData="userData"
        @submit="handleScanner_Submit"
      />
    </template>
  </kst-section>
</template>

<script>
import Catalog from "../SpadCatalog.js";
import Model from "../SpadModel.js";
import Services from "../../../services/Api/SpecialPriceAdjServices.js";

import List from "./SpadN_Items_List.vue";
import Scanner from "./SpadN_Items_Scanner.vue";
import StockSearchDefault from "../../Stock/SearchListDefault/StkScLDefault.vue";

export default {
  components: {
    List,
    Scanner,
    StockSearchDefault
  },
  props: {
    itemList: Array,
    userData: Object,
    userItems: Array,
    specialPriceOptions: Object
  },
  data: () => ({
    Catalog: Catalog,
    Model: Model,
    // func
    showStockFunc: null
  }),
  computed: {
    invalidItems() {
      return Model.getInvalidItems(this.userData);
    }
  },
  methods: {
    /*** by stock modal ***/

    handleStock_Init(show) {
      this.showStockFunc = show;
    },
    handleStock_Loaded(stockList) {
      Model.updateItemsByStockList(this.userItems, stockList);
      Model.validateItemsByStock(this.userData, this.userItems);
    },
    handleStock_Submit(stockData, itemData) {
      let item = Model.createItem();
      Model.setItemByStock(item, stockData, itemData.ItemDetails,
        itemData.StockSpecialPriceList, this.specialPriceOptions
      );
      Model.updateItemByStockSpecialPrice(item);
      Model.updateItem(item);
      this.userItems.push(item);

      Model.updateDetails(this.userData, this.userItems);
    },

    /*** custom ***/

    handleAdd_Item() {
      this.showStockFunc(
        this.userData.WarehouseID,
        Services.getRelatedStockList,
        Services.getRelatedItemDetails
      );
    },

    handleScanner_Submit(itemData, qty, stockDetails) {
      let item = Model.createItem();
      Model.setItemByScanner(item, itemData, qty, stockDetails, 
        this.specialPriceOptions);
      Model.updateItem(item);
      this.userItems.push(item);
    }
  }
}
</script>