import AppModule from "./AppModule.js";
import AppModuleType from "./AppModuleType.js";
import InputType from "../Input/InputType.js";
import OutputType from "../Input/OutputType.js";

/**
 * Store static values (constantas) for the App, which are used in certain place.
 * If the values are use in wide-spread components, store them at App.js
 */
export default {
  /*** app catalog ***/

  Title: "Dirigo",
  Copyright: "© 2021 Dirigo",
  Copyright2: "By PT Kreatifitas Sinergisme TeknoIndo",
  Description: "We’re provider of quality IT solutions and reliable service in software dev.",

  /*** general catalog ***/

  CurrencySign: "Rp",
  DaySign: "hari",
  DecimalNumber: 2, // number of decimals digit
  PackagingPcs: {
    Name: "pcs",
    Qty: 1
  },
  PercentDec100: 100, // percent value in decimal type
  PercentInt100: 1, // percent value in integer type
  PercentSign: "%",

  /*** default catalog ***/

  Company: {
    MutiaraBali: {
      CompanyID: 21
    }
  },

  // chart.js
  Chart: {
    Canvas: {
      Height: 400
    }
  },

  ClassPrefix: {
    Module: "kstmod", // module component
    Shared: "kst" // shared component
  },

  Folder: {
    Public: {
      Images: "/images/"
    }
  },

  IntlFormat: {
    Currency: "IDR",
    Number: "id-ID"
  },

  /*** mixin ***/

  RouterMixin: {
    Clear: {
      Delay: 500 // in miliseconds
    }
  },

  Search: {
    Debounce: {
      Delay: 1000 // in miliseconds
    }
  },
  Snackbar: {
    ActionText: "OK",
    Duration: 5000 // in miliseconds
  },

  /*** shared component ***/

  Action: {
    Icon: {
      Default: "menu-down",
      New: "plus-thick"
    },
    Mode: {
      Button: "is-primary",
      Normal: "is-info"
    },
    Prefix: {
      Module: "Module",
      ModuleType: "ModuleType",
      UrlParams: "UrlParams"
    }
  },
  Actions: {
    HasDefault: {
      WithoutAlwaysVisible: {
        Class: "kst-actions__without-always-visible-action "
      }
    }
  },

  Barcode: {
    Height: 100,
    Width: 4
  },
  
  Button: {
    Action: {
      Class: "is-white ",
      // Icon: handled by developer
      // Label: handled by developer
    },
    Cancel: {
      Class: "ks-button",
      Icon: "mdi mdi-arrow-left-circle mdi-18px",
      Label: "Batal"
    },
    CheckAll: {
      Class: "is-primary",
      Icon: "mdi mdi-checkbox-multiple-marked-outline mdi-18px",
      Label: "Pilih Semua"
    },
    Clear: {
      Class: "is-danger",
      Icon: "mdi mdi-close-circle mdi-18px",
      Label: undefined
    },
    Close: {
      Class: "",
      Icon: "mdi mdi-close-circle mdi-18px",
      Label: "Batal"
    },
    Confirm: {
      Class: "is-primary",
      Icon: "mdi mdi-check-circle mdi-18px",
      Label: "OK"
    },
    Delete: {
      Class: "is-danger",
      Icon: "mdi mdi-delete mdi-18px",
      Label: undefined
    },
    Edit: {
      Class: "is-link",
      Icon: "mdi mdi-pencil mdi-18px",
      Label: undefined
    },
    FullPayment: {
      Class: "is-primary",
      Icon: "mdi mdi-pencil mdi-18px",
      Label: "Bayar Penuh"
    },
    FullReceived: {
      Class: "is-primary",
      Icon: "mdi mdi-pencil mdi-18px",
      Label: "Diterima Penuh"
    },
    Mail: {
      Class: "is-link",
      Icon: "mdi mdi-email-outline mdi-18px",
      Label: undefined
    },
    Mobile: {
      Class: "is-hidden-touch "
    },
    Phone: {
      Class: "is-link",
      Icon: "mdi mdi-phone mdi-18px",
      Label: undefined
    },
    Print: {
      Class: "is-primary",
      Icon: "mdi mdi-printer mdi-18px",
      Label: "Print"
    },
    Random: {
      Class: "is-primary",
      Icon: undefined,
      Label: "Isi Acak"
    },
    Refresh: {
      Class: "is-info",
      Icon: "mdi mdi-refresh mdi-18px",
      Label: "Perbarui"
    },
    Reload: {
      Class: "is-danger",
      Icon: "mdi mdi-reload mdi-18px",
      Label: "Muat Ulang"
    },
    Reset: {
      Class: "is-danger",
      Icon: "mdi mdi-undo mdi-18px",
      Label: "Reset"
    },
    Search: {
      Class: "is-link",
      Icon: "mdi mdi-magnify mdi-18px",
      Label: undefined
    },
    SignIn: {
      Class: "is-primary",
      Icon: "mdi mdi-login mdi-18px",
      Label: "Masuk"
    },
    SignOut: {
      Class: "is-danger",
      Icon: "mdi mdi-logout mdi-18px",
      Label: "Keluar"
    },
    Submit: {
      Class: "is-primary ks-button",
      Icon: "mdi mdi-content-save mdi-18px",
      Label: "Simpan"
    },
    UncheckAll: {
      Class: "is-primary",
      Icon: "mdi mdi-checkbox-multiple-blank-outline mdi-18px",
      Label: "Hapus Terpilih"
    },
    Url: {
      Class: "is-link",
      Icon: "mdi mdi-open-in-new mdi-18px",
      Label: undefined
    },
    Verify: {
      Class: "is-primary",
      Icon: "mdi mdi-text-box-check-outline mdi-18px",
      Label: "Verify"
    }
  },

  Failure: {
    Hint: "Mohon coba kembali, atau hubungi administrator anda",
    MessageTitle: "Pesan:",
    TitleData: "Gagal mengambil data",
    TitleInit: "Gagal mengambil data",
    TitleList: "Gagal mengambil data",
    TitleSave: "Gagal menyimpan data"
  },

  Icon: {
    AddCart: {
      Class: "has-text-success ",
      Icon: "mdi mdi-cart-plus mdi-24px",
      Label: undefined
    },
    ArrowRight: {
      Class: "has-text-info ",
      Icon: "mdi mdi-arrow-right-bold mdi-24px",
      Label: undefined
    },
    Collapse: {
      Collapsed: {
        Icon: "mdi mdi-chevron-down"
      },
      Expanded: {
        Icon: "mdi mdi-chevron-up"
      }
    },
    Error: {
      Class: "has-text-danger ",
      Icon: "mdi mdi-alert-circle mdi-24px",
      Label: undefined
    },
    Info: {
      Class: "has-text-info ",
      Icon: "mdi mdi-information mdi-24px",
      Label: undefined
    },
    ItemActive: {
      Active: {
        Class: "has-text-success ",
        Icon: "mdi mdi-toggle-switch mdi-24px",
        Label: undefined
      },
      InActive: {
        Class: "has-text-danger ",
        Icon: "mdi mdi-toggle-switch-off mdi-24px",
        Label: undefined
      }
    },
    ItemStatus: {
      Open: {
        Class: "has-text-success ",
        Icon: "mdi mdi-toggle-switch-off mdi-24px",
        Label: undefined
      },
      Closed: {
        Class: "has-text-danger ",
        Icon: "mdi mdi-toggle-switch mdi-24px",
        Label: undefined
      }
    },
    NewItem: {
      Class: "has-text-info ",
      Icon: "mdi mdi-new-box mdi-24px",
      Label: undefined
    },
    Status: {
      Active: {
        Class: "has-text-success ",
        Icon: "mdi mdi-circle",
        Label: undefined
      },
      InActive: {
        Class: "has-text-danger ",
        Icon: "mdi mdi-circle",
        Label: undefined
      }
    },
    Warning: {
      Class: "has-text-warning ",
      Icon: "mdi mdi-alert mdi-24px",
      Label: undefined
    }
  },

  InfiniteScroll: {
    PerPage: 10
  },

  Image: {
    Accept: "image/*",
    AddText: "Tambah Gambar",
    Icon: "mdi mdi-plus-thick mdi-48px",
    NoText: "Tanpa Gambar",

    Source: {
      Brand: "brand_logo.png",
      CompanyDefault: "company_default_logo.png",
      Invalid: "invalid.png" // not available yet
    }
  },

  Print: {
    PrintedAt: "Dicetak pada: ", // +timestamp
    PrintedBy: "oleh: ", // +username
    Error: {
      Help: "Pesan:", // +error_message
      Info: "Tidak dapat menampilkan data."
    }
  },

  Output: {
    Related: {
      Class: "is-link "
    },
    TextArea: {
      Rows: 3
    }
  },

  Router: {
    Back: {
      Class: "is-link ",
      Icon: "mdi mdi-arrow-left-circle mdi-18px",
      Label: "Kembali"
    },
    Forward: {
      Class: "is-link ",
      Icon: "mdi mdi-arrow-right-circle mdi-18px",
      Label: "Lanjut"
    }
  },

  Section: {
    Scanner: "Pemindai",

    // help
    SelectOne: "Pilih salah satu",

    // mode
    Confirmation: "Konfirmasi",
    Export: "Pilihan Export:", // similar to Export.Name in "@/services/App/AppModuleType.js"
    Info: "Info ", // prefix
    Items: AppModuleType.List.Name + " " + AppModule.Item.FullName,
    List: "Daftar ", // prefix
    Print: "Pilihan Print:", // similar to Print.Name in "@/services/App/AppModuleType.js"
    Search: "Pencarian",
    SearchResult: "Hasil Pencarian"
  },

  Table: {
    PerPage: 20,
    DefaultSortDirection: "Desc",
    SortIcon: "menu-up",
    SortIconSize: "is-small"
  },

  Tooltip: {
    Clear: {
      Content: "Menghapus ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-danger"
    },
    Delete: {
      Content: "Menghapus ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-danger"
    },
    DeleteImage: {
      Content: "Menghapus gambar",
      Position: "is-bottom",
      Size: undefined,
      Type: "is-danger"
    },
    Details: {
      Content: "Rincian ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-info"
    },
    Edit: {
      Content: "Edit ", // prefix
      Position: "is-bottom",
      Size: "is-medium",
      Type: "is-info"
    },
    Error: {
      Content: undefined,
      Position: "is-bottom",
      Size: "is-medium",
      Type: "is-danger"
    },
    Info: {
      Content: undefined,
      Position: "is-bottom",
      Size: "is-medium",
      Type: "is-info"
    },
    Mail: {
      Content: "Mengirim email",
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    },
    NewItem: {
      Content: "Barang baru (belum terdaftar)",
      Position: "is-bottom",
      Size: undefined,
      Type: "is-info"
    },
    Phone: {
      Content: "Melakukan panggilan",
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    },
    Related: {
      Content: "Melihat " + AppModuleType.Details.Name + " ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    },
    Search: {
      Content: "Mencari ", // prefix
      Position: "is-left",
      Size: undefined,
      Type: "is-info"
    },
    Url: {
      Content: "Membuka tautan",
      Position: "is-left",
      Size: undefined,
      Type: "is-link"
    }
  },

  // cleave
  Cleave: {
    // source: https://github.com/nosir/cleave.js/blob/master/doc/options.md#numeraldecimalscale
    DefaultDecimalScale: 2,
    DecimalMark: ",",
    ThousandMark: "."
  },

  // compressorjs package
  Compressorjs: {
    MaxWidth: 1000 // in pixel
  },

  // duration
  Duration: {
    // in miliseconds
    Loading: 3000
  },

  Extension: {
    Jpg: ".jpg",
    Jpeg: ".jpeg",
    Png: ".png"
  },

  Field: {
    Address: {
      Label: "Alamat",
      Type: InputType.Text
    },
    Alias: {
      Label: "Alias",
      Type: InputType.Text
    },
    Average: {
      Label: "Rata-Rata",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Currency
    },
    BackDateInterval: {
      Label: "Tanggal Mundur",
      Type: InputType.Integer,
      MaxLength: 3,
      MinValue: 0,
      MaxValue: 365, // days, 1 year
    },
    Balance: {
      Label: "Saldo",
      Type: InputType.Integer,
      Output: OutputType.Integer.Currency,
      ReadOnly: true // absolute
    },
    Barcode: {
      Label: "Barcode",
      Type: InputType.Text,
      MaxLength: 50
    },
    BuyPrice: {
      Label: "Harga Beli",
      Type: InputType.Integer,
      PrintLabel: "Harga",
      MinValue: 0,
      MaxLength: 11,
      Output: OutputType.Integer.Currency
    },
    Cash: {
      Label: "Cash"
    },
    Cashier: {
      Label: "Kasir"
    },
    CashReceived: {
      Label: "Uang Diterima",
      Type: InputType.Integer,
      MinValue: 0,
      MaxLength: 11,
      Output: OutputType.Integer.Currency
    },
    Category: {
      Label: "Kategori",
      Param: "category"
    },
    Code: {
      Label: "Code",
      Type: InputType.Text
    },
    Comment: {
      Label: "Catatan",
      Type: InputType.TextArea,
      AltType: InputType.Text
    },
    Counter: {
      Label: "Perulangan",
      Type: InputType.Integer,
    },
    CounterLength: {
      Label: "Panjang Perulangan",
      Type: InputType.Integer,
    },
    CreatedBy: {
      Label: "Pembuat",
      Type: InputType.Text
    },
    CreatedByID: {
      Label: "Nama",
      Type: InputType.ID,
      RelatedValue: "CreatedByName",
      RelatedModule: AppModule.UserList
    },
    CreatedDate: {
      Label: "Waktu",
      Type: InputType.DateTime,
      ReadOnly: true, // absolute
      Output: OutputType.DateTime.ShortDateTime
    },
    Credit: {
      Label: "Kredit",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Accounting2D,
      ReadOnly: true // absolute
    },
    CreditAmount: {
      Label: "Total Kredit",
      Type: InputType.Integer,
      Output: OutputType.Integer.Currency,
      ReadOnly: true // absolute
    },
    Date: {
      Label: "Tanggal",
      Type: InputType.Date,
      Param: "date"
    },
    DaysDueDate: {
      Label: "Jumlah Tempo (hari)",
      Type: InputType.Integer,
      MinValue: 0,
      MaxLength: 11
    },
    Debit: {
      Label: "Debit",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Accounting2D,
      ReadOnly: true // absolute
    },
    DebtDueReminderDays: {
      Label: "Batas Waktu Kirim Email Pengingat Hutang",
      Type: InputType.Integer,
      MinValue: 0
    },
    DebtEmailLastSent: {
      Label: "Waktu Kirim Email Pengingat Hutang Terakhir",
      Type: InputType.DateTime,
      ReadOnly: true
    },
    DebtEmailSentTime: {
      Label: "Waktu Kirim Email Pengingat Hutang",
      Type: InputType.Time,
    },
    Default: {
      Label: "Default" // prefix
      // Type: based on app module
    },
    Destination: {
      Label: "Tujuan",
      Type: InputType.Text,
      Param: "dst"
    },
    Disc: {
      Label: "Diskon"
      // Type: based on disc-type: value, percent.
    },
    DiscPercent: {
      Label: "Diskon (%)",
      ExportLabel: "Diskon",
      PrintLabel: "Diskon",
      Type: InputType.Percent,
      MinValue: 0,
      MaxValue: 100
    },
    DiscType: {
      Label: "Pilihan Diskon",
      Type: InputType.Select
    },
    DiscValue: {
      Label: "Diskon (Rp)",
      ExportLabel: "Diskon",
      PrintLabel: "Diskon",
      Type: InputType.Integer,
      MaxLength: 11,
      MinValue: 0,
      Output: OutputType.Integer.Currency
    },
    DispatchSellPrice: {
      Label: "Harga Barang",
      Type: InputType.Integer,
      ReadOnly: true, // absolute
      Output: OutputType.Integer.Currency
    },
    DueDate: {
      Label: "Tanggal Jatuh Tempo",
      Type: InputType.Date
    },
    Email: {
      Label: "Email",
      Type: InputType.Email,
    },
    Fee: {
      Label: "Biaya",
      Type: InputType.Integer,
      MinValue: 0,
      MaxLength: 11,
      Output: OutputType.Integer.Currency
    },
    Hpp: {
      Label: "Harga Pokok",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Accounting2D
    },
    HppPerPcs: {
      Label: "Hpp Per Pcs",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Accounting2D,
      ReadOnly: true // absolute
    },
    HppTotal: {
      Label: "Harga Pokok Total",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Accounting2D,
      ReadOnly: true // absolute
    },
    ID: {
      // Label: based on app module
      Type: InputType.ID,
      Input: InputType.Select,
      Param: "id"
    },
    ImageSrc: {
      Label: "Gambar",
      Type: InputType.Text // should use Image type when supported
    },
    IsActive: {
      Label: "Status",
      Type: InputType.Boolean,
      SearchType: InputType.Integer,
      SearchInput: InputType.Select,
      Output: OutputType.Boolean.ActiveInactive,
      Param: "status"
    },
    IsAutoNumber: {
      Label: "", // per module
      Type: InputType.Boolean,
      Output: OutputType.Boolean.AutoManual
    },
    IsAutoRenewal: {
      Label: "Pembaharuan Otomatis",
      Type: InputType.Boolean,
      Output: OutputType.Boolean.YesNo
    },
    IsClosed: {
      Label: "Status",
      Type: InputType.Boolean,
      Output: OutputType.Boolean.OpenClosed
    },
    LastInvoiceDate: {
      Label: "Penjualan Terakhir",
      Type: InputType.Date,
      Param: "lastinvdate"
    },
    LastUpdatedBy: {
      Label: "Diperbarui",
      Type: InputType.Text
    },
    LastUpdatedByID: {
      Label: "Nama",
      Type: InputType.ID,
      RelatedValue: "LastUpdatedByName",
      RelatedModule: AppModule.UserList
    },
    LastUpdatedDate: {
      Label: "Waktu",
      Type: InputType.DateTime,
      ReadOnly: true, // absolute
      Output: OutputType.DateTime.ShortDateTime
    },
    Lifetime: {
      Label: "Waktu Aktif (hari)",
      Type: InputType.Integer,
      MinValue: 1,
      MaxLength: 11
    },
    LimitQty: {
      Label: "Limit Qty",
      Type: InputType.Integer,
      MinValue: 0
    },
    LimitIsActiveDate: {
      Label: "Tanggal Stock Rendah",
      Type: InputType.Date
    },
    LowStockEmailLastSent: {
      Label: "Waktu Kirim Email Pengingat Stock Rendah Terakhir",
      Type: InputType.DateTime
    },
    MobilePhone: {
      Label: "HP",
      Type: InputType.Phone
    },
    Month: {
      Label: "Bulan",
      Type: InputType.Integer,
    },
    Name: {
      Label: "Nama",
      Type: InputType.Text,
      Param: "name"
    },
    NextRenewal: {
      Label: "Next Renewal",
      Type: InputType.Date
    },
    NoPIC: {
      Label: "NO PIC",
      Type: InputType.Phone
    },
    Number: {
      Label: "Nomor",
      Type: InputType.Text,
      MaxLength: 50 // suggested
    },
    Overdue: {
      Label: "Jatuh Tempo",
      Type: InputType.Integer,
      Output: OutputType.Integer.Currency,
      ReadOnly: true // absolute
    },
    OverDueDate: {
      Label: "Lewat Jatuh Tempo",
      Type: InputType.Date
    },
    Password: {
      Label: "Password",
      Type: InputType.Password,
      MaxLength: 64,
      MinLength: 8,
      Required: true
    },
    PaymentReturn: {
      Label: "Kembalian",
      Type: InputType.Integer,
      Output: OutputType.Integer.Accounting
    },
    PaymentValue: {
      Label: "Jumlah Bayar",
      Type: InputType.Integer,
      AltType: InputType.Decimal,
      Output: OutputType.Integer.Accounting,
      AltOutput: OutputType.Integer.Currency,
      AltOutput2: OutputType.Decimal.Accounting2D,
      MinValue: 1, // optional
      AltMinValue: 0, // optional: use to cancel payment
      MaxLength: 11
    },
    Pcs: {
      Label: "Pcs"
    },
    Phone: {
      Label: "Telp",
      Type: InputType.Phone,
    },
    Profit: {
      Label: "Laba",
      Type: InputType.Decimal,
      Output: OutputType.Decimal.Accounting2D,
      ReadOnly: true // absolute
    },
    ProfitMargin: {
      Label: "Laba Margin",
      Type: InputType.Percent,
      ReadOnly: true
    },
    PrefixSuffix: {
      Label: "Awalan/Akhiran",
      Type: InputType.Text,
    },
    PIC: {
      Label: "PIC",
      Type: InputType.Text
    },
    Pin: {
      Label: "PIN",
      Type: InputType.Password
    },
    Qty: {
      Label: "Qty",
      Type: InputType.Integer,
      AltType: InputType.Qty,
      MinValue: 1,
      AltMinValue: 0,
      MaxLength: 11
    },
    QtyStock: {
      Label: "Qty Stok",
      Type: InputType.Integer
    },
    Reason: {
      Label: "Alasan",
      Type: InputType.TextArea
    },
    RecDueReminderDays: {
      Label: "Batas Waktu Kirim Email Pengingat Piutang",
      Type: InputType.Integer,
      MinValue: 0
    },
    RecEmailLastSent: {
      Label: "Waktu Kirim Email Pengingat Piutang Terakhir",
      Type: InputType.DateTime,
      ReadOnly: true
    },
    RecEmailSentTime: {
      Label: "Waktu Kirim Email Pengingat Piutang",
      Type: InputType.Time,
    },
    RecordTypeID: {
      Label: "Jenis Data",
      AltLabel: "Jenis",
      Type: InputType.ID,
      Input: InputType.Select
    },
    Reference: {
      Label: "Referensi",
      Type: InputType.Text
    },
    Search: {
      Label: "Cari", // prefix
      Type: InputType.Text,
      MaxLength: 50,
      Param: "search"
    },
    SearchResult: {
      Label: "Hasil Pencarian", 
      Type: InputType.ID,
      Param: "searchresult"
    },
    SellPrice: {
      Label: "Harga Jual",
      Type: InputType.Integer,
      PrintLabel: "Harga",
      MinValue: 0,
      MaxLength: 11,
      Output: OutputType.Integer.Currency
    },
    SKU: {
      Label: "SKU",
      Type: InputType.Text
    },
    Source: {
      Label: "Sumber Data",
      AltLabel: "Sumber",
      Type: InputType.ID,
      Input: InputType.Select
    },
    Status: {
      Label: "Status",
      Type: InputType.ID,
      Input: InputType.Select, // optional
      Param: "status"
    },
    Stock: {
      Label: "Stok",
      Type: InputType.Integer,
      ReadOnly: true // absolute
    },
    SubTotal: {
      Label: "Sub Total",
      Type: InputType.Integer,
      Output: OutputType.Integer.Currency,
      ReadOnly: true // absolute
    },
    Total: {
      Label: "Total",
      Type: InputType.Integer,
      AltType: InputType.Decimal,
      Output: OutputType.Integer.Currency,
      AltOutput: OutputType.Decimal.Currency,
      AltOutput2: OutputType.Decimal.Accounting2D,
      ReadOnly: true, // absolute
      MinValue: 1 // optional
    },
    TotalDisc: {
      Label: "Total Diskon",
      Type: InputType.Integer,
      ReadOnly: true, // absolute
      Output: OutputType.Integer.Currency
    },
    TotalQty: {
      Label: "Total Qty",
      AltLabel: "Jumlah Barang",
      Type: InputType.Integer,
      ReadOnly: true // absolute
    },
    TotalWeight: {
      Label: "Total Berat (gram)",
      Type: InputType.Integer,
      ReadOnly: true // absolute
    },
    Type: {
      Label: "Tipe",
      Type: InputType.Text
    },
    Url: {
      Label: "Url",
      Type: InputType.Url
    },
    Verify: {
      Label: "Verify",
      Type: InputType.Boolean
    },
    Weight: {
      Label: "Berat (gram)",
      Type: InputType.Integer,
      MinValue: 0,
      MaxLength: 11
    },
    Year: {
      Label: "Tahun",
      Type: InputType.Integer,
      Output: OutputType.Integer.Text
    }
  },

  Format: {
    DateSeparator: "/",
    DateRangeSeparator: "-",
    ValueRangeSeparator: "-",

    // date
    ShortDate: "DD/MM/YYYY",

    // datetime
    ShortDateTime: "DD/MM/YYYY HH:mm",
    MediumDateTime: "DD MMM YYYY HH:mm",
    LongDateTime: "dddd, DD MMM YYYY HH:mm",

    // time
    LongTime: "HH:mm:ss",
    ShortTime: "HH:mm",

    // credential
    PasswordMask: "***"
  },

  Help: {
    StockSummary: "Sesuai data tgl."
  },

  // used in: catalog, model
  Info: {
    AreYouSureToDeactivate: "Yakin untuk menon-aktifkan", // prefix
    AreYouSureToDelete: "Yakin untuk menghapus", // prefix
    Company: "Perusahaan",
    Delivery: "Pengiriman",
    Download: "Download",
    ID: "ID",
    DeletedData: "(Telah Dihapus)",
    DeletedItem: AppModule.Item.FullName + " terhapus",
    Example: "contoh",
    Faktur: "Faktur",
    GrossProfit: "Laba Kotor",
    HPP: "HPP",
    Instruction: "Petunjuk",
    Description: "Keterangan",
    Login: "Login",
    Logo: "Logo",
    NewItem: AppModule.Item.FullName + " baru (belum terdaftar)",
    NettProfit: "Laba Bersih",
    NotificationReminder: "Pengingat Notifikasi",
    Owner: "Owner",
    Profit: "Laba",
    ProfitMargin: "Laba Margin",
    Report: "Lihat Laporan",
    Return: "Retur",
    SalesSelfService: "Layanan Pemesanan Mandiri",
    Sequence: "#",
    SelfService: "Layanan Mandiri",
    Service: "Layanan",
    Shop: "Toko",
    ShopService: "Layanan Toko",
    Summary: "Ringkasan",
    Template: "Template",
    Today: "hari ini",
    Update: "Ubah",
    View: "Lihat"
  },
  InfoSign: {
    Header: {
      ApprovedBy: "Disetujui Oleh,",
      CreatedBy: "Dibuat Oleh,",
      ReceivedBy: "Diterima Oleh,"
    }
  },

  Input: {
    Required: {
      Text: "Wajib",
      Type: "is-danger is-light"
    }
  },

  // message
  Message: {
    All: "Semua",
    CannotRestoreDeletedData: "Data tidak dapat dipulihkan setelah dihapus",
    Error_ArrayBlank: "Wajib diisi",
    Error_SelectOne: "Wajib memilih salah satu",
    ErrorOnForm: "Mohon perbaiki kesalahan",
    FileRequired: "Pilih file terlebih dahulu.",
    InfoEmpty: "Tidak ada info.",
    InfoOnForm: "Informasi tambahan",
    Input: "Masukan",
    Invoice_Statement: "Barang yang sudah dibeli tidak dapat dikembalikan.",
    New: "Baru",
    Old: "Lama",
    LeavePage: [
      "Yakin meninggalkan halaman ini?",
      "Anda akan kehilangan data yang sudah diinput."
    ],
    ListEmpty: "Tidak ada data.",
    NeedToVerify: "Wajib diverifikasi.",
    NoAccess: "Tidak memiliki akses.",
    None: "Tidak ada.",
    ParamIncomplete: "Data tidak sesuai.",
    SuccessOnForm: "Data berhasil disimpan.",
    TableEmpty: "Tidak ada data.",
    TryAgain: "Silahkan coba lagi.",
    Unlimited: "Tanpa Batas.",
    ReloadPage: [
      "Yakin reload halaman ini?",
      "Anda akan kehilangan data yang sudah diinput."
    ],
    Wait: "Mohon menunggu...",
    WarningOnForm: "Harap diperhatikan",
    // message with suffix
    ConfirmDelete: "Yakin menghapus",
    FailedToLoadFile: "Tidak dapat memuat file",
    MustHave: "Wajib memiliki",
    Some: "Beberapa",
    Without: "Tanpa",
    // message with affix
    MustNumeric: "harus berisi angka",
    // message with prefix
    InActive: "tidak aktif.",
    InUse: "telah dipergunakan.",
    InValid: "tidak sesuai.",
    MustEqualsTo: "Harus sama dengan",
    NotAvailable: "tidak tersedia.",
    NotExist: "tidak ditemukan.",
    NotDefinedYet: "belum ditentukan.",
    Selected: "terpilih"
  },

  MonthNames: [
    "Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"
  ],
  MonthLongNames: [
    "Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli",
    "Agustus", "September", "Oktober", "November", "Desember"
  ],

  VeeValidate: {
    Provider: {
      /**
       * {Boolean} - Bails
       * > true: return first-error found, or
       *   false: return all-errors found
       * {String} - Class - class name for ValidationProvider
       * {Boolean} - Immediate
       * > true: validate field when loaded, or
       *   false: validate on user action or manually validate
       * {String} - Tag - tag name for ValidationProvider
       */
      // 
      Bails: false,
      Class: "ks-is-fullwidth ",
      Immediate: false,
      Tag: "div"
    }
  },

  Xlsx: {
    Default: {
      Extension: ".xlsx",
      MaxSize: 2097152, // 2 MB in bytes
      Password: "***",
      Sequence: "#"
    },
    Format: {
      // keys based on: "@/services/Input/OutputTypeEnum.js"
      // date & time
      ShortDate: "dd/mm/yyyy",
      MediumDate: "dd mmm yyyy",
      LongDate: "dd mmmm yyyy",
      ShortMonthly: "mmm yyyy",
      LongMonthly: "mmmm yyyy",
      ShortDateTime: "dd/mm/yyyy hh:mm",
      MediumDateTime: "dd mmm yyyy hh:mm",
      LongDateTime: "dd mmmm yyyy hh:mm",
      // number
      Accounting: "_(\"Rp\" * #,##0_);_((\"Rp\" * #,##0);_(\"Rp\" * "-"_);_(@_)",
      Accounting2D: "_(\"Rp\" * #,##0.00_);_((\"Rp\" * #,##0.00);_(\"Rp\" * \"-\"??_);_(@_)",
      Currency: "\"Rp\" #,##0",
      Decimal2D: "#,##0.00",
      Integer: "#,##0",
      Percent: "0.00%",
    },
    Key: {
      Column: "c",
      Format: "z",
      Formula: "f",
      Style: "s",
      Row: "r",
      Type: "t",
      Value: "v"
    },
    Options: {
      Array: 'array'
    },
    Position: {
      Start: "s",
      End: "e",
    },
    Type: {
      Boolean: "b",
      Error: "e",
      Number: "n",
      Date: "d",
      Text: "s",
      Stub: "z"
    },
    WorkSheet: {
      Merges: "!merges"
    }
  }
}