import App from "../../services/App/App.js";
import ModelBody from "./SpadModel_Body.js";
import ModelDetails from "./SpadModel_Details.js";
import SpecialPriceModel from "../SpecialPrice/SpModel.js";
import WarehouseModel from "../Warehouse/WhsModel.js";

export default {
  // required in model
  Module: ModelBody.Module,
  ModuleType: ModelBody.ModuleType,

  /*** related ***/

  SpadItem: ModelBody.SpadItem,

  /*** property ***/

  Access: ModelBody.Access,
  Actions: ModelBody.Actions,
  Search: ModelBody.Search,

  /*** method ***/

  createDetails() {
    return ModelBody.createDetails();
  },
  createItem() {
    return ModelBody.createItem();
  },

  setDetailsBySpecialPrice(userData, specialPriceCount) {
    ModelBody.setDetailsBySpecialPrice(userData, specialPriceCount);
  },
  setDetailsByWarehouseList(userData, warehouseList) {
    return ModelBody.setDetailsByWarehouseList(userData, warehouseList);
  },

  setItemByScanner(userItem, itemData, qty, stockDetails, specialPriceOptions) {
    ModelBody.setItemByScanner(userItem, itemData, qty, stockDetails,
      specialPriceOptions
    );
  },
  setItemByStock(userItem, stockData, stockDetails, 
    stockSpecialPriceList, specialPriceOptions
  ) {
    ModelBody.setItemByStock(userItem, stockData, stockDetails,
      stockSpecialPriceList, specialPriceOptions
    );
  },
  setItemErrors(item, field, errors) {
    item.Errors = App.Vee.getRowErrors(item, field, errors);
  },

  getItemInfo(userItem) {
    return ModelBody.getItemInfo(userItem);
  },

  getInvalidItems(details) {
    return ModelBody.getInvalidItems(details);
  },

  updateDetails(userData, userItems) {
    ModelBody.updateDetails(userData, userItems);
  },

  updateItemsByStockList(userItems, stockList) {
    ModelBody.updateItemsByStockList(userItems, stockList);
  },

  updateItem(userItem) {
    ModelBody.updateItem(userItem);
  },
  updateItemByPackaging(userItem) {
    ModelBody.updateItemByPackaging(userItem);
  },
  updateItemBySpecialPrice(userItem) {
    ModelBody.updateItemBySpecialPrice(userItem);
  },
  updateItemByStockSpecialPrice(userItem) {
    ModelBody.updateItemByStockSpecialPrice(userItem);
  },
  updateItemReadOnly(userItem) {
    ModelBody.updateItemReadOnly(userItem);
  },

  validateItemsByStock(userData, userItems) {
    ModelBody.validateItemsByStock(userData, userItems);
  },

  populateData(userData, userItems) {
    let result = ModelBody.populateDetails(userData);
    result.Items = ModelBody.populateItems(userItems);
    return result;
  },

  /*** method - Details ***/

  Details: {
    getItemInfo(userItem) {
      return ModelDetails.getItemInfo(userItem);
    }
  },

  /*** method - List ***/

  List: {
    getActions(row, access) {
      return App.Access.getDetailsAvailableActions(
        row.ID, ModelBody.Actions.List, ModelBody.Module, access
      );
    },
    hasAccessAction(access) {
      return access.Act_Details ||
        access.Act_PrintDetails;
    }
  },

  /*** external ***/

  getSpecialPriceOptions(specialPriceList, optionAllText, optionNonText) {
    return SpecialPriceModel.createOptions(specialPriceList, optionAllText,
      optionNonText
    );
  },
  getWarehouseOptions(warehouseList, optionAllText) {
    return WarehouseModel.createOptions(warehouseList, optionAllText);
  }
}